<template>
  <base-layout>
    <!--ion-searchbar show-cancel-button="focus" :value="searchterm" type="search"
                   @ionInput="searchterm = $event.target.value;"></ion-searchbar-->
    <div class="input-group mb-3" style="margin: 5px 10px; max-width:99%">
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" disabled>
        <font-awesome-icon :icon="['fal','search']"/>
      </button>
      <input type="text" class="form-control" :placeholder="this.wordings[this.currentLanguage].SEARCH "  aria-describedby="button-addon2"
             v-model="searchterm" style="border-radius: 0">
      <button class="btn btn-outline-secondary" type="button" id="button-addon2"
              v-if="searchterm && searchterm.length > 0"
              @click="filter(searchterm)">
        <font-awesome-icon :icon="['fal','search']"/>
      </button>
      <button class="btn btn-outline-secondary" type="button" v-if="searchterm && searchterm.length > 0"
              @click="searchterm = '' ;filter('')">
        <font-awesome-icon :icon="['fal','times']"/>
      </button>
    </div>
    <ion-content class="has-header has-subheader">
      <ion-item v-if="searchDone && completeResults == 0">
       {{ wordings[currentLanguage].NO_RESULT_FIRST }} {{ searchterm }}
        {{ wordings[currentLanguage].NO_RESULT_SECOND }}
      </ion-item>
      <div class="accordion" id="accordionExample" style="margin-top: 10px">
        <div class="accordion-item" v-if="filteredSessions.length > 0">
          <h2 class="accordion-header" id="sessionHeading">
            <button class=" position-relative accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSessions" aria-expanded="false" aria-controls="collapseSessions">
               {{ wordings[currentLanguage].SECTION_SESSION }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute translate-middle badge rounded-pill bg-primary">
                {{ filteredSessions.length }}
              </span>
            </button>
          </h2>
          <div id="collapseSessions" class="accordion-collapse collapse" aria-labelledby="sessionHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <session-list :sessions="filteredSessions"></session-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="filteredPresentations.length > 0">
          <h2 class="accordion-header" id="presentationHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePresentations" aria-expanded="false" aria-controls="collapsePresentations">
                {{ wordings[currentLanguage].PRESENTATION_HEADER }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute translate-middle badge rounded-pill bg-primary">
                {{ filteredPresentations.length }}
              </span>
            </button>
          </h2>
          <div id="collapsePresentations" class="accordion-collapse collapse" aria-labelledby="presentationHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <presentation-list :presentations="filteredPresentations" :with-date="true"></presentation-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="filteredAbstracts.length > 0">
          <h2 class="accordion-header" id="abstractHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseAbstracts" aria-expanded="false" aria-controls="collapseAbstracts">
                {{ wordings[currentLanguage].SECTION_ABSTRACTS }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute translate-middle badge rounded-pill bg-primary">
                {{ filteredAbstracts.length }}
              </span>
            </button>
          </h2>
          <div id="collapseAbstracts" class="accordion-collapse collapse" aria-labelledby="abstractHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <abstract-list :abstracts="filteredAbstracts"></abstract-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="filteredPersons.length > 0">
          <h2 class="accordion-header" id="personHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePersons" aria-expanded="false" aria-controls="collapsePersons">
                {{ wordings[currentLanguage].SECTION_PERSON }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute translate-middle badge rounded-pill bg-primary">
                {{ filteredPersons.length }}
              </span>
            </button>
          </h2>
          <div id="collapsePersons" class="accordion-collapse collapse" aria-labelledby="personHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <person-list :persons="filteredPersons"></person-list>
            </div>
          </div>
        </div>
        <div class="accordion-item" v-if="filteredExhibitors.length > 0">
          <h2 class="accordion-header" id="exhibitorHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExhibitors" aria-expanded="false" aria-controls="collapseExhibitors">
              Exhibitors
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute translate-middle badge rounded-pill bg-primary">
                {{ filteredExhibitors.length }}
              </span>
            </button>
          </h2>
          <div id="collapseExhibitors" class="accordion-collapse collapse" aria-labelledby="exhibitorHeading"
               data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <exhibitor-list :exhibitors="filteredExhibitors"></exhibitor-list>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, /*IonSearchbar*/} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import SessionList from "@/components/SessionList";
import PersonList from "@/components/PersonList";
import PresentationList from "@/components/PresentationList";
import ExhibitorList from "@/components/ExhibitorList";
import AbstractList from "@/components/AbstractList";

export default defineComponent({
  name: "Search",
  data() {
    return {
      searchterm: "",
      sessions: [],
      persons: [],
      exhibitors: [],
      presentations: [],
      abstracts: [],
      filteredSessions: [],
      filteredPersons: [],
      filteredExhibitors: [],
      filteredPresentations: [],
      filteredAbstracts: [],
      searchDone: false,
      completeResults: 0
    };
  },
  components: {
    IonContent,
    //IonSearchbar,
    "session-list": SessionList,
    "person-list": PersonList,
    "presentation-list": PresentationList,
    "exhibitor-list": ExhibitorList,
    "abstract-list": AbstractList
  },
  methods: {
    ...mapActions('sessions', ['getAllSessions', 'getSessionListLive']),
    ...mapActions("presentations", ["getAllPresentations","getAllPresentationsWithLink"]),
    ...mapActions("persons", ["getAllPersons"]),
    ...mapActions("abstracts", ["getAllAbstracts"]),
    ...mapActions("exhibitors", ["getList"]),
    filter(term) {
      if (term.length > 0) {
        this.filteredSessions = this.sessions.length > 0 ? this.prepareResultList(this.sessions, term) : [];
        this.filteredPersons = this.persons.length > 0 ? this.prepareResultList(this.persons, term) : [];
        this.filteredPresentations = this.presentations.length > 0 ? this.prepareResultList(this.presentations, term) : [];
        this.filteredExhibitors = this.exhibitors.length > 0 ? this.prepareResultList(this.exhibitors, term) : [];
        this.filteredAbstracts = this.abstracts.length > 0 ? this.prepareResultList(this.abstracts, term) : [];
        this.searchDone = true;
        this.completeResults = this.filteredSessions.length + this.filteredPersons.length + this.filteredPresentations.length + this.filteredExhibitors.length + this.filteredAbstracts.length;
      } else {
        this.searchDone = false;
        this.completeResults = 0;
        this.filteredSessions = [];
        this.filteredPersons = [];
        this.filteredPresentations = [];
        this.filteredAbstracts = [];
        this.filteredExhibitors = [];
      }
    },
    prepareResultList(list, term) {
      return list.filter(
          item => Object.keys(item).some(k => {
            if (item[k] && typeof item[k] == 'string') {
              return item[k].toLowerCase().includes(term.toLowerCase())
            } else if (item[k] && typeof item[k] == 'object') {
              return JSON.stringify(item[k]).toLowerCase().includes(term.toLowerCase());
            } else {
              return ''
            }
          })
      )
    }
  },
  computed: {
        ...mapState('wordings',['wordings', 'currentLanguage']),

  },

  async created() {
    this.sessions = await this.getSessionListLive();
    this.exhibitors = await this.getList();
    this.persons = await this.getAllPersons();
    this.presentations = await this.getAllPresentationsWithLink();
    this.abstracts = await this.getAllAbstracts();
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Search with term') {
          this.searchterm = route.params.term || localStorage.getItem('lastSearchTerm');
          console.log('SEARCH', this.searchterm)
          if (this.sessions.length == 0) this.sessions = await this.getSessionListLive();
          if (this.exhibitors.length == 0) this.exhibitors = await this.getList();
          if (this.persons.length == 0) this.persons = await this.getAllPersons();
          if (this.presentations.length == 0) this.presentations = await this.getAllPresentationsWithLink();
          if (this.abstracts.length == 0) this.abstracts = await this.getAllAbstracts();
          if (this.searchterm.length > 0) {
            await this.filter(this.searchterm)
          }
        } else if (route.name == 'Search') {
          this.searchterm = localStorage.getItem('lastSearchTerm');
          console.log('SEARCH', this.searchterm)
          if (this.sessions.length == 0) this.sessions = await this.getSessionListLive();
          if (this.exhibitors.length == 0) this.exhibitors = await this.getList();
          if (this.persons.length == 0) this.persons = await this.getAllPersons();
          if (this.presentations.length == 0) this.presentations = await this.getAllPresentationsWithLink();
          if (this.abstracts.length == 0) this.abstracts = await this.getAllAbstracts();
          if (this.searchterm.length > 0) {
            await this.filter(this.searchterm)
          }
        } else {
          if (this.searchterm) {
            localStorage.setItem('lastSearchTerm', this.searchterm);
          }
        }
      }
    },

    searchterm: async function (newVal) {
      if (newVal.length == 0) {
        this.filter('');
        localStorage.removeItem('lastSearchTerm');

      }
    },
  }
});
</script>
<style lang="scss">
.accordion-body {
  padding: 0
}
</style>
